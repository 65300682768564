
import React from 'react';
import {
  Row,
  Container
} from 'react-bootstrap';

import CourseMaps from '../CourseMaps.js';


import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import faq from '../faq'
import 'react-accessible-accordion/dist/fancy-example.css';

import TypeTransparent from '../../assets/2024/HotdoggerMainLogoTransparentWPink-min.png'

export default function TeamOverview() {

  const features = [
    {
      name: 'Event Dates',
      description:
        'The event will begin at sunrise on October 19th, 2024, with a starting ceremony and conclude at sunset with an afterparty.',
      icon: '🗓️',
    },
    {
      name: 'What Is Included',
      description: 'Registration covers camping, hotdogs, buns, condiments, a Hotdogger water bottle, socks, stickers, and a donation to COPMOBA. Your team will be assigned a campsite before the event.',
      icon: '🎟️',
    },
    {
      name: 'Creating A Team',
      description:
        'Teams must have a unique name and consist of at minimum 1, and maximum 6 members (this is a hard limit). Team points will be the sum of all individual scores.',
      icon: '🤼',
    },
    {
      name: 'Participation',
      description:
        'Team members may choose to participate in only one aspect of the biathlon, although participation in both activities is strongly encouraged.',
      icon: '🥇',
    },
  ];

  return (
    <>
      <div className="bg-babyblue pt-12 pb-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <img src={TypeTransparent} />
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-hotdoggerbrown">
                      <p>{feature.icon}</p>
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                </div>
              ))}
            </dl>
            <div class="mt-12 mb-6 text-center">
              <a
                class="group relative inline-block focus:outline-none focus:ring"
                href="https://www.bikereg.com/thehotdogger2024"
                target='_blank'
              >
                <span
                  class="absolute inset-0 translate-x-0 translate-y-0 bg-hotdoggerorange transition-transform group-hover:translate-y-1.5 group-hover:translate-x-1.5"
                ></span>

                <span
                  class="relative inline-block border-2 border-current px-8 py-3 text-sm font-bold uppercase tracking-widest"
                >
                  Register
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <CourseMaps></CourseMaps>
      <div className='bg-slate-100'>
        <div className="py-10 max-w-screen-xl mx-auto">
          <Container className="px-4 mx-auto">
            <Row className="align-items-center py-4 text-dark">
              <p class="text-3xl font-bold tracking-tight text-pinkslime sm:text-4xl">Frequently Asked Questions:</p>
              <p className="mt-2 text-lg leading-8 text-slate-800 mb-4">Please direct all other questions to <a href="mailto:hotdogger.mtb@gmail.com">hotdogger.mtb@gmail.com</a></p>
              <Accordion className='bg-neutral-100 mx-auto' allowZeroExpanded>
                {faq.map((item, itemIndex) => (
                  <AccordionItem key={itemIndex}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        {item.question}
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel style={{ border: '1px solid #e5e5e5' }}>
                      {item.answer}
                    </AccordionItemPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}
