import Copmobalogo from './Sponsors/assets/COPMOBA_PrimaryLogo_White-01.png'
import Pic from '../../assets/ai-art/linebike.png'

export default function Copmoba() {
    return (
        <div className="bg-pinkslime" style={{padding: '1rem'}}>
            <section
                class="overflow-hidden grid-cols-1 sm:grid sm:grid-cols-2 sm:items-center max-w-5xl mx-auto"
            >
                <div class="pt-8 md:p-12 lg:px-10 lg:py-24">
                    <div class="ml-auto max-w-xl text-center sm:text-left">
                        <h2 class="text-2xl font-bold text-gray-900 dark:text-white md:text-3xl">
                            The Hotdogger supports COPMOBA
                        </h2>

                        <p class="text-gray-200 md:mt-4 md:block">
                            Founded in 1989, the Colorado Plateau Mountain Bike Trail Association is a nonprofit organization dedicated to building, maintaining, and advocating for sustainable singletrack trails and developmental features in the Colorado Plateau region of western Colorado.
                        </p>

                        <div class="mt-4 md:mt-8">
                            <a
                                class="group relative inline-block focus:outline-none focus:ring"
                                href="https://www.copmoba.org/"
                                target='_blank'
                            >
                                <span
                                    class="absolute inset-0 translate-x-0 translate-y-0 bg-mustard transition-transform group-hover:translate-y-1.5 group-hover:translate-x-1.5"
                                ></span>

                                <span
                                    class="relative inline-block border-2 border-current px-8 py-3 text-sm font-bold uppercase tracking-widest"
                                >
                                    Learn More
                                </span>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='sm:my-auto items-center'>
                    <img src={Copmobalogo} className="w-full sm:max-w-full mx-auto lg:w-full sm:mx-0 sm:mr-auto lg:pr-10" />
                </div>
            </section>

        </div>
    )
}