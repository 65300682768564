import DogImage from '../../assets/2024/Endo-min.png'

const components = [
    {
        name: 'Hotdogs Eaten',
        description:
            'Participants will be asked to self report the number of hotdogs they consume throughout the race. Hotdogs must be consumed with a bun (substitutes due to dietary restrictions will be accepted).',
        icon: "🌭",
    },
    {
        name: 'Laps Biked',
        description:
            'Participants will be awarded points for every lap biked. Points will only be awarded for laps that follow the course standard. Lap point totals vary based on length/difficulty. For more detail please see the course overview.',
        icon: "🚲",
    },
]

export default function Scoring() {
    return (
        <>
            <div style={{ backgroundColor: '#E4788E' }} className="overflow-hidden py-16 sm:py-22">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                        <div className="lg:pr-8 lg:pt-4">
                            <div className="lg:max-w-lg">
                                <div class="sticky top-0 mb-3">
                                    <strong
                                        class="rounded-full border border-blue-500 bg-blue-200 px-3 py-0.5 text-xs font-medium tracking-wide text-blue-500"
                                    >
                                        The Hotdogger
                                    </strong>
                                    </div>
                                    <p className="mt-2 text-3xl font-extrabold tracking-tight text-hotdoggerbrown sm:text-5xl">Event Scoring Guide</p>
                                    <p className="mt-6 text-lg leading-8 text-hotdoggerbrown">
                                        Winning isn't everything, it's the only thing. The following is a guide to the scoring system used to determine the winner of The Hotdogger, the greatest competition in sports.
                                    </p>
                                    <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-hotdoggerbrown lg:max-w-none">
                                        {components.map((component) => (
                                            <div key={component.name} className="relative pl-9">
                                                <dt className="inline font-semibold text-babyblue">
                                                    {component.icon} {' '}
                                                    {component.name}
                                                </dt>{' '}
                                                <dd className="inline">{component.description}</dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>
                            </div>
                            <div className="flex justify-center items-center lg:ml-8 px-6">
                                <img
                                    src={DogImage}
                                    alt="Product screenshot"
                                    className="w-full h-full object-center object-cover rounded-xl"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>

            )
}
