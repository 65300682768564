import SquareLogo from '../../assets/2023/hotdoggerfinalnobackground-01-min.png';

import Wave from './Wave';

export default function MainHero() {
    return (
        <>
            <section className='overflow-hidden' style={{ backgroundColor: '#B5E3F9' }}>
                <div className="mx-auto max-w-screen-xl px-4 lg:pt-4 sm:px-6">
                    <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-4">
                        <img
                            alt="Hotdogger Logo"
                            src={SquareLogo}
                            className="hidden lg:block relative h-full w-full"
                        />

                        <img
                            alt="Hotdogger Logo"
                            src={SquareLogo}
                            className="w-8/12 mx-auto hidden sm:block lg:hidden"
                        />

                        <img
                            alt="Hotdogger Logo"
                            src={SquareLogo}
                            className="w-screen mx-auto sm:hidden"
                        />

                        <div className="flex flex-col justify-center">
                            <p className="hidden sm:block text-3xl sm:text-4xl font-sans font-bold tracking-wide" style={{ color: '#2C150D' }}>
                                The premier hotdog eating contest, endurance mountain bike race, and charity fundraiser.
                            </p>
                            <p className="sm:mt-4 text-xl font-light" style={{ color: '#2C150D' }}>
                                The Hotdogger is a mountain bike race and hotdog eating contest taking place on Oct. 19th 2024, from sunrise to sunset, in Fruita, CO. It is a non-profit event to benefit the Colorado Plateau Mountain Bike Trail Association.
                                <br></br> <br></br>
                                Registration for The Hotdogger will open on June 9th, 2024 at 5pm MST 🎉
                            </p>

                            <div className="inline-block py-2">
                                <a
                                    className="my-6 md:mb-0 group relative inline-flex items-center overflow-hidden rounded bg-hotdoggerorange px-8 py-3 text-white focus:outline-none focus:ring"
                                    href="https://www.bikereg.com/thehotdogger2024"
                                    target='_blank'
                                >
                                    <span className="absolute left-0 -translate-x-full transition-transform group-hover:translate-x-4">
                                        <svg
                                            className="h-5 w-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                                            />
                                        </svg>
                                    </span>

                                    <span className="text-lg font-medium transition-all group-hover:ml-4">
                                        Register
                                    </span>
                                </a>
                                <a
                                    className="my-6 ml-4 md:mb-0 group relative inline-flex items-center overflow-hidden rounded bg-neutral-400 px-8 py-3 text-white focus:outline-none focus:ring"
                                    href="/About"
                                >
                                    <span className="absolute left-0 -translate-x-full transition-transform group-hover:translate-x-4">
                                        <svg
                                            className="h-5 w-5"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                                            />
                                        </svg>
                                    </span>

                                    <span className="text-lg font-medium transition-all group-hover:ml-4">
                                        About
                                    </span>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
                <Wave />
            </section>
        </>
    );
}