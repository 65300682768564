import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import GalleryLabel from './general-assets/hotdoggerbanner.png'
import TwentyOneLabel from './2021-Assets/IMG_6834.jpg'
import TwentyTwoLabel from './2022-Assets/9CEB7EE9-A864-45B8-8854-6DF2B093F35C.jpeg'
import TwentyThreeLabel from './2023-Assets/IMG_7436.CR2.jpg'
import { AlbumLabelLarge, AlbumLabelSmall } from './AlbumLabels.js'

const GalleryList = ({ onClick }) => (
    <>
        <ul class="grid grid-cols-1 gap-4 mt-8 lg:grid-cols-3">
            <AlbumLabelSmall onClick={onClick} buttonText="View Album" id={2021} title={'2021'} coverImg={TwentyOneLabel} />
            <AlbumLabelSmall onClick={onClick} buttonText="View Album" id={2022} title={'2022'} coverImg={TwentyTwoLabel} />
            <AlbumLabelLarge onClick={onClick} buttonText="View Album" id={2023} title={'2023'} coverImg={TwentyThreeLabel} />
        </ul>
    </>
)


const PhotoGallery = () => {
    const TextComp = () => (<p class="max-w-md mx-auto mt-4 text-gray-500"> Want to see how much fun you could be having at the dogger? Check out our photo galleries below!</p>)

    const navigate = useNavigate();
    const handleClick = (id) => {
        navigate(`./${id}`);
    };

    return (

        <section className="bg-cream flex-grow overflow-auto h-100">
            <div class="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 sm:py-12 lg:px-8">
                <header class="text-center">
                    <img src={GalleryLabel} className='w-full mx-auto lg:px-32 mb-10' />
                    <TextComp />
                </header>
                <GalleryList onClick={(id) => { handleClick(id) }} />
                <div className='pb-6 md:pb-0'></div>
            </div>
        </section>

    )
}



export default PhotoGallery;