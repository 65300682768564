import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const AlbumBrowser = ({ onBack, albumId, albumDescription }) => {
    const { year } = useParams();
    const [images, setImages] = useState(null);

    useEffect(() => {
        const importImages = async () => {
            let importedImages = [];
            try {
                switch (albumId) {
                    case '2021':
                        importedImages = await importAll(require.context('./2021-Assets', false, /\.(png|jpe?g|svg)$/));
                        break;
                    case '2022':
                        importedImages = await importAll(require.context('./2022-Assets', false, /\.(png|jpe?g|svg)$/));
                        break;
                    case '2023':
                        importedImages = await importAll(require.context('./2023-Assets', false, /\.(png|jpe?g|svg)$/));
                        break;
                    default:
                        importedImages = [];
                }
            } catch (error) {
                console.error('Error loading images:', error);
                importedImages = [];
            }
            setImages(importedImages);
        };

        importImages();
    }, [albumId]);

    function importAll(r) {
        return r.keys().map(r);
    }

    return (
        <div className="bg-cream h-min-100vh">
            <div className="px-6 md:px-20 lg:max-w-screen-xl mx-auto">
                <header>
                    <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
                        <div className="sm:flex sm:items-center sm:justify-between">
                            <div className="text-center sm:text-left">
                                <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                                    You're viewing the {albumId} album!
                                </h1>

                                <p className="mt-1.5 text-sm text-gray-500">
                                    {albumDescription}
                                </p>
                            </div>

                            <div className="text-center mt-4 block flex-col gap-4 sm:mt-0 sm:flex-row sm:items-center">
                                <a
                                    className="group relative inline-block focus:outline-none focus:ring"
                                    href=""
                                    onClick={(event) => { event.preventDefault(); onBack() }}
                                >
                                    <span
                                        className="absolute inset-0 translate-x-0 translate-y-0 bg-babyblue transition-transform group-hover:translate-y-1.5 group-hover:translate-x-1.5"
                                    ></span>

                                    <span
                                        className="relative inline-block border-2 border-current px-8 py-3 text-sm font-bold uppercase tracking-widest"
                                    >
                                        Back
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="grid grid-cols-6 col-span-3 gap-2 pb-6">
                    {albumId && images && images.length > 0 && images.map((image, imageIndex) => (
                        <div key={imageIndex} className="overflow-hidden rounded-xl col-span-6 sm:col-span-3 md:col-span-2 max-h-[25rem]">
                            <img
                                loading="lazy"
                                className="object-cover w-full h-full"
                                src={image}
                                srcSet={`${image}?w=320 320w, ${image}?w=480 480w, ${image}?w=800 800w`}
                                sizes="(max-width: 600px) 320px, (max-width: 900px) 480px, 800px"
                                width="100%"
                                height="100%"
                                alt="image"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AlbumBrowser;
