import React from 'react';

import PbrDrawn from '../assets/PBR_drawn.png';
import JrDrawn from '../assets/jr_map.png';
import ZippityDrawn from '../assets/Zippity_MAP.png';
import KesselDrawn from '../assets/Kessel_Run_Drawn.png';

const CourseMap = ({ link, title, description, img }) => {
  return (
    <div className="group relative flex flex-col h-full">
      <div className="my-3">
        <div className="inline-flex items-left w-min gap-3 h-min">
          <span className="inline-flex items-center justify-center rounded-full bg-babyblue px-2.5 py-0.5 text-blue-600 w-min">
            🏅
            <p className="whitespace-nowrap text-sm">
              Pts:{' '}
              {title === 'PBR'
                ? '🌭🌭'
                : title === "Joe's Ridge"
                ? '🌭🌭🌭'
                : title === 'Kessel Run'
                ? '🌭'
                : '🌭🌭🌭🌭🌭'}
            </p>
          </span>
          <span className="inline-flex items-center justify-center rounded-full bg-mustard px-2.5 py-0.5 text-yellow-700 w-min">
            📈
            <p className="ml-1 whitespace-nowrap text-sm">
              {title === 'PBR'
                ? '4.3 Mi, 369 ft'
                : title === "Joe's Ridge"
                ? '4.4 Mi, 551 ft'
                : title === "Kessel Run"
                ? '2.1 Mi, 440 ft'
                : '6 Mi, 823 ft'}
            </p>
          </span>
        </div>
      </div>
      <a href={link} target="_blank" className="relative flex flex-col h-full">
        {/* Dashed border */}
        <span className="sm:mt-3 absolute inset-0 sm:border-2 sm:border-dashed border-black"></span>

        <div className="relative items-end sm:mt-3 md:border-hotdoggerorange md:bg-slate-200 md:group-hover:-translate-x-2 md:group-hover:-translate-y-2 aspect-[5/6] md:group-hover:bg-gray-800 transition-colors duration-300">
          <img
            src={img}
            alt={title}
            className="w-full h-full object-cover transition-opacity duration-300 md:group-hover:opacity-0"
          />

          <div className="absolute inset-0 p-4 pt-0 md:transition-opacity md:group-hover:opacity-0 md:p-6 lg:py-6 flex items-end">
            <h2 className="mt-4 text-xl font-medium sm:text-xl">{title}</h2>
          </div>

          <div className="absolute inset-0 p-4 opacity-0 md:transition-opacity md:group-hover:opacity-100 md:p-6 lg:p-8 flex flex-col justify-center">
            <h3 className="mt-4 text-xl font-medium sm:text-2xl">{title}</h3>
            <p className="mt-4 text-sm sm:text-base">{description}</p>
            <p className="mt-8 font-bold">Learn More</p>
          </div>
        </div>
      </a>
    </div>
  );
};

export default function CourseMaps() {
  return (
    <>
      <div className="bg-hotdoggerorange py-12 px-2">
        <div className="mx-auto max-w-7xl sm:px-6 xl:px-8">
          <div className="mx-auto max-w-2xl xl:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-hotdoggerbrown sm:text-4xl">
              Course Overview
            </h2>
            <p className="mt-2 mb-4 text-lg leading-8 text-hotdoggerbrown">
              Each team member will earn Hotdog points for every lap completed on one of the below
              routes. Each route is worth a set number of points, click on a route for more
              information.
            </p>
          </div>
          <div className="grid gap-8 mx-auto grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
            <CourseMap
              link="https://caltopo.com/m/N00JB"
              title="Kessel Run"
              description="Your admins have raced this in less than twelve parsecs"
              img={KesselDrawn}
            />
            <CourseMap
              link="https://caltopo.com/m/N00JB"
              title="PBR"
              description="What'll You Have?"
              img={PbrDrawn}
            />
            <CourseMap
              link="https://caltopo.com/m/N00JB"
              title="Joe's Ridge"
              description="Mo points mo betta"
              img={JrDrawn}
            />
            <CourseMap
              link="https://caltopo.com/m/N00JB"
              title="Zippity"
              description="Legs feed the wolf"
              img={ZippityDrawn}
            />
          </div>
        </div>
      </div>
    </>
  );
}
