import React from 'react';
import Copmoba from './Copmoba';
import MainHero from './MainHero';
import SponsorNascar from './Sponsors/SponsorNascar';
import Footer from '../Footer';

export default function Home() {

    return (
        <>
        <MainHero />
        <SponsorNascar />
        <Copmoba />
        {/** <Footer /> */}
        </>
    )
}