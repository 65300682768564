import Footer from "../Footer"
import Scoring from "./Scoring"
import TeamOverview from "./TeamOverview"

export default function About() {
    return(
        <>
        <Scoring />
        <TeamOverview />
        {/* <Footer /> */}
        </>
    )
}