import { useState } from 'react'

import dprairie from '../../assets/ai-art/dogprar.png'
import DiscountCodes from './DiscountCodes'


const DiscountDoghouse = () => {

    const [allowed, setAllowed] = useState(false)

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const navigation = [
        { name: 'Product', href: '#' },
        { name: 'Features', href: '#' },
        { name: 'Marketplace', href: '#' },
        { name: 'Company', href: '#' },
    ]
    const submitForm = () => {
        const code = document.getElementById('dc-code').value;
        if (code.toUpperCase() === 'FRUITA') {
            setAllowed(true);
        }
        else {
            alert('try again');
        }
    }
    return (
        <>
            <section className="bg-slate-200 overflow-auto">
                {allowed ?
                    <DiscountCodes />
                    :
                    <div className="bg-slate-200">

                        <div className="px-6 pt-14 lg:px-8 my-auto pb-10">
                            <div className="mx-auto max-w-2xl">
                                <div className="text-center">
                                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                        Welcome to the Discount Doghouse
                                    </h1>
                                    <p className="mt-6 text-lg leading-8 text-gray-600">
                                    Enter the code provided in your registration confirmation email to get access to the Discount Doghouse!
                                    </p>
                                    <form id='myform' class=" px-8 pt-6" onSubmit={() => submitForm()}>
                                        <div class="mb-4">
                                            <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="dc-code" type="text" placeholder="Discount Code" />
                                        </div>
                                    </form>
                                    <div className="mt-6 flex items-center justify-center gap-x-6 pb-8">
                                        <div
                                            style={{ cursor: 'pointer' }}
                                            class="group relative inline-block focus:outline-none focus:ring"
                                            onClick={() => submitForm()}
                                        >
                                            <span
                                                class="absolute inset-0 translate-x-0 translate-y-0 bg-indigo-400 transition-transform group-hover:translate-y-1.5 group-hover:translate-x-1.5"
                                            ></span>

                                            <span
                                                class="relative inline-block border-2 border-current px-8 py-3 text-sm font-bold uppercase tracking-widest"
                                            >
                                                Let Me In
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <img src={dprairie} class="w-4/12 mx-auto rounded rounded-xl shadow"/>
                            </div>
                        </div>
                    </div>
                }

            </section >
        </>
    )
}

export default DiscountDoghouse;