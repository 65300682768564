

import PSILogo from '../Home/Sponsors/assets/psilogo1.png'
import OTELogo from '../Home/Sponsors/assets/Oval_DINO_AND_BIKE_LOGO_fullcolor.png'
import MucOffLogo from '../Home/Sponsors/assets/MucOffLogo.png'
import SBLogo from '../Home/Sponsors/assets/SunBumLogo.png'
import IbexLogo from '../Home/Sponsors/assets/IbexLogo.png'

import dprairie from '../../assets/ai-art/dogprar.png'

export default function DiscountDoghouse() {


    const DiscountSponsors = [
        {
            name: 'Party Shirt International',
            description:
                `Use Code 'hotdogger' for 20% off apparel on their website`,
            logo: PSILogo,
            url: 'https://partyshirtinternational.com',
            code: 'hotdogger'
        },
        {
            name: 'Ibex Apparel',
            description:
                `Use code 'RELISHTHERIDE' for 25% off everything on their website!`,
            logo: IbexLogo,
            code: 'RELISHTHERIDE',
            url: 'https://ibex.com/'
        },
        {
            name: 'Over The Edge Sports',
            description:
                'This local shop will be offering 20% off of rentals + 20% regular merchandise (non-bike), and free early pick up on Friday and free late return on Sunday ($50 value). To rent a bike you will need to call in  and reserve over the phone.',
            logo: OTELogo,
            url: 'https://www.otefruita.com',
            code: 'In Store Only'
        },
        {
            name: 'Muc-Off',
            description:
                `Use code 'HOTDOGGER20' for 20% off of their website`,
            logo: MucOffLogo,
            url: 'https://us.muc-off.com/blogs/press',
            code: 'HOTDOGGER20'
        },
        {
            name: 'Sun Bum',
            description:
                `Use code 'SBLOVESU20!' for 20% off of their website`,
            logo: SBLogo,
            url: 'https://www.sunbum.com',
            code: 'SBLOVESU20!'
        },
    ]
    

    return (
        <div className="py-10 bg-slate-200 h-content w-full">

            <section class="max-w-5xl mx-auto sm:grid sm:grid-cols-2 px-10">
                <div class="p-8 md:p-12 lg:px-16 lg:py-20">
                    <div
                        class="mx-auto max-w-xl text-center"
                    >
                        <p className="text-5xl font-bold tracking-tight text-dark sm:text-5xl">
                            Discounts
                        </p>

                        <p className="mt-4 text-xl leading-8 text-gray-800">
                            These amazing sponsors have provided some sweet discount codes exclusively for Hotdogger Participants, head on over to their site to check them out!
                        </p>

                    </div>
                </div>

                <img
                    alt="Student"
                    src={dprairie}
                    class="w-80 mx-auto rounded-xl shadow"
                />
            </section>
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto mt-6 max-w-2xl lg:max-w-5xl">
                    <dl className="w-full flex flex-wrap gap-6 md:gap-8 justify-center">
                        {DiscountSponsors.map((sponsor) => (
                            <>
                                <a href={sponsor.url} target="_blank" className="group relative block h-80 w-full mx-8 md:mx-0 md:w-80 text-center">
                                    <span className="absolute inset-0 border-2 border-dashed border-black"></span>
                                    <div className="relative flex h-full transform items-end border-2 border-black bg-white transition-transform group-hover:-translate-x-2 group-hover:-translate-y-2">
                                        <div className="px-8 pb-8 transition-opacity group-hover:absolute group-hover:opacity-0 w-full">
                                            <div className="w-full h-full">
                                                <img
                                                    alt="Sponsor Logo"
                                                    src={sponsor.logo}
                                                    className="object-contain h-56 w-112 text-center mx-auto"
                                                />
                                                <span
                                                    class="absolute right-5 mt-5 inline-flex items-center justify-center rounded-full bg-emerald-100 px-2.5 py-0.5 text-emerald-700"
                                                >
                                                    💰

                                                    <p class="ml-1 whitespace-nowrap text-sm">Code: {sponsor.code}</p>
                                                </span>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                                            </svg>
                                        </div>
                                        <div className="absolute p-8 opacity-0 transition-opacity group-hover:relative group-hover:opacity-100" >
                                            <h3 className="mt-4 text-2xl font-medium">{sponsor.name}</h3>
                                            <p className="mt-4">
                                                {sponsor.description}
                                            </p>
                                            <p className="mt-8 font-bold">Check Them Out</p>
                                        </div>
                                    </div>
                                </a>
                            </>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
