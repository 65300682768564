const AlbumLabelSmall = ({ id, onClick, coverImg, title, buttonText }) => {

    return (
        <>
            <li>
                <a onClick={(event) => { event.preventDefault(); onClick(id) }} href="#" class="relative block group">
                    <img
                        src={coverImg}
                        alt=""
                        class="object-cover w-full transition duration-500 aspect-square group-hover:opacity-90"
                    />

                    <div
                        class="absolute inset-0 flex flex-col items-start justify-end p-6"
                    >
                        <span
                            class="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-sm text-purple-700"
                        >
                            {title}
                        </span>
                        <span
                            class="mt-1.5 inline-block bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
                        >
                            {buttonText}
                        </span>
                    </div>
                </a>
            </li>
        </>
    )
}

const AlbumLabelLarge = ({ id, onClick, coverImg, title }) => {

    return (
        <>
            <li class="lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-1">
                <a onClick={(event) => { event.preventDefault(); onClick(id) }} href="#" class="relative block group">
                    <img
                        src={coverImg}
                        alt=""
                        class="object-cover w-full transition duration-500 aspect-square group-hover:opacity-90"
                    />

                    <div
                        class="absolute inset-0 flex flex-col items-start justify-end p-6"
                    >
                        <span
                            class="whitespace-nowrap rounded-full bg-purple-100 px-2.5 py-0.5 text-sm text-purple-700"
                        >
                            {title}
                        </span>
                        <span
                            class="mt-1.5 inline-block bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide text-white"
                        >
                            View Album
                        </span>
                    </div>
                </a>
            </li>
        </>
    )
}

export { AlbumLabelSmall, AlbumLabelLarge }