import React from 'react';
import {
  Route,
  Routes,
  Navigate,
  useSearchParams
} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import PageNav from './components/PageNav';
import About from './components/About/About';
import Home from './components/Home/Home';
import PhotoGalleryRoutes from './components/PhotoGallery/PhotoGalleryRoutes';
import DiscountDoghouse from './components/DiscountDoghouse';

function App() {

  return (
    <div className='flex flex-col h-100 min-height-100vh bg-red'>
      <PageNav />
      <Routes>
        <Route path="/Home" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/DiscountDoghouse" element={<DiscountDoghouse />} />
        <Route path="/Gallery/*" element={<PhotoGalleryRoutes />} />
        <Route path="/" element={<Navigate to="/Home" replace />} />
        <Route path="*" element={<Navigate to="/Home" replace />} />
      </Routes>
      <Toaster />
    </div>

  );

}

export default App;

